// extracted by mini-css-extract-plugin
export var container = "home-module--container--ulkMn";
export var introMessage = "home-module--introMessage--wDaxo";
export var outerLeft = "home-module--outerLeft--NeAE1";
export var introMessageInner = "home-module--introMessageInner--qX1CQ";
export var innerLeft = "home-module--innerLeft--TokHM";
export var tag = "home-module--tag--jthiC";
export var textClipEnter = "home-module--textClipEnter--IEzzS";
export var textShow = "home-module--textShow--Nnkzz";
export var greetingText = "home-module--greetingText--iWfBb";
export var firstName = "home-module--firstName--vplja";
export var lastName = "home-module--lastName--aKJQu";
export var explore = "home-module--explore--uv6F9";
export var exploreTextEnter = "home-module--exploreTextEnter--cNG32";
export var link = "home-module--link--kUNcv";
export var image = "home-module--image--cwVG8";
export var imageIn = "home-module--imageIn--qCWwJ";